import { SET_IS_AUTHENTICATED, SET_NAME } from './constants';

export const setIsAuthenticated = (payload: any) => ({
  type: SET_IS_AUTHENTICATED,
  payload,
});

export const setName = (payload: any) => ({
  type: SET_NAME,
  payload,
});
