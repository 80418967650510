import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row, Typography } from 'antd';
import * as Yup from 'yup';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { messageUI } from '@/utils/text/message';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import { CompanyApi, WebhookForm } from '@/apis';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Field,
  FormItem,
  Input,
  Modal,
  Select,
} from '@/components/common';
import { commonUI } from '@/utils/text/UI';
import { APP_ROUTER } from '@/routes/routes';
import {
  LeftCircleOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { convertObjectToArray } from '@/utils/helpers';
import { isFlagOptions } from '@/const/option';
import { getCookie, KeyCookie } from '@/utils/storage';
import { useEffect, useState } from 'react';
import { ENDPOINT } from '@/apis/endpoint';
import { regex } from '@/utils';

const FormWebhook: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object({
    urlEndpoint: Yup.string()
      .trim()
      .required(messageUI.urlEndPointRequired)
      .test('urlEndPointInvalid', messageUI.webhookUrlInvalid, (value) => {
        if (!value) return true;
        return regex.validateLink.test(value);
      }),
    authorization: Yup.string().trim().nullable(),
    isFlag: Yup.number().required(),
    headers: Yup.array().of(
      Yup.object().shape({
        headerName: Yup.string().trim().required(messageUI.headerNameRequired),
        headerValue: Yup.string()
          .trim()
          .required(messageUI.headerValueRequired),
      }),
    ),
  });
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const {
    fields: headersFields,
    append: appendHeader,
    remove: removeHeader,
  } = useFieldArray({
    control,
    name: 'headers',
  });

  const user = getCookie(KeyCookie.currentUser);
  let parsedUser: any = {};
  parsedUser = JSON.parse(user);

  useEffect(() => {
    setValue('companyName', parsedUser?.company?.name);
  }, []);

  const { data: dataWebhook } = useQuery({
    queryKey: [ENDPOINT.WEBHOOK.GET_DETAIL, Number(parsedUser?.company.id)],
    queryFn: async () => {
      try {
        const response = await CompanyApi.getWebhook(
          Number(parsedUser?.company.id),
        );
        if (response === null) {
          navigate('/webhook/create');
        } else {
          navigate('/webhook/update');
        }
        return response;
      } catch (error: any) {
        toastMessage(
          NotificationType.error,
          ErrorMessage[error.error as ErrorMessageKeys] ??
            getMessageSubmitForm(error as any),
        );
        throw error;
      }
    },
    enabled: !!parsedUser?.company.id,
  });

  const mutationCreate = useMutation({
    mutationFn: CompanyApi.createWebhook,
    onSuccess: async () => {
      navigate(APP_ROUTER.WEBHOOK.DETAIL);
      toastMessage(NotificationType.success, messageUI.createSuccess);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: CompanyApi.updateWebhook,
    onSuccess: async () => {
      navigate(APP_ROUTER.WEBHOOK.DETAIL);
      toastMessage(NotificationType.success, messageUI.updateSuccess);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });
  const onSubmit = async (values: WebhookForm) => {
    dataWebhook && setOpen(true);
    const data: any = {
      companyId: parsedUser?.company.id,
      isFlag: !!values.isFlag,
      authorization: values.authorization,
      urlEndpoint: values.urlEndpoint,
      headers: values.headers,
    };
    if (open) {
      mutationUpdate.mutate(data);
    }
    if (!dataWebhook) {
      mutationCreate.mutate(data);
    }
  };

  useEffect(() => {
    setValue('isFlag', 1);
  }, []);

  useEffect(() => {
    let nameCompany: any = {};
    nameCompany = JSON.parse(user);
    if (dataWebhook) {
      const importDataForm = {
        ...dataWebhook,
        companyName: nameCompany?.company?.name,
        isFlag: dataWebhook.isFlag ? 1 : 0,
      };
      reset(importDataForm);
    }
  }, [dataWebhook, reset]);

  return (
    <div>
      <FormProvider {...methods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='w-full h-full flex flex-col'
          labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
          labelAlign='left'
          layout='vertical'
        >
          <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='text-2xl font-semibold'>
              {dataWebhook ? commonUI.editWebhook : commonUI.createWebhook}
            </div>
          </div>
          <div className='w-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
            <div>
              <div className='flex justify-between'>
                <Typography.Title level={4} className='flex'>
                  <div className='text-xl mb-4'>{commonUI.infoWebhook}</div>
                </Typography.Title>
                <div>
                  <Button
                    htmlType='submit'
                    type='primary'
                    block={true}
                    className='w-full max-w-xs tracking-[-1.5px]'
                  >
                    {dataWebhook === null ? commonUI.create : commonUI.update}
                  </Button>
                </div>
              </div>
              <div>
                <Row gutter={[64, 4]}>
                  <Col xs={24} lg={12}>
                    <Field
                      name='companyName'
                      label={commonUI.companyName}
                      required
                    >
                      <Input disabled />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='isFlag' label={commonUI.isFlag} required>
                      <Select
                        placeholder={messageUI.placeholderSelect}
                        options={convertObjectToArray(isFlagOptions)}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field
                      name='urlEndpoint'
                      label={commonUI.urlEndPoint}
                      required
                    >
                      <Input maxLength={100} />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='authorization' label={commonUI.authorization}>
                      <Input maxLength={100} />
                    </Field>
                  </Col>
                </Row>
              </div>
              <div>
                <FormItem name='headers'>
                  <>
                    {headersFields.map((field, index) => {
                      const object = (errors.headers as any)?.reduce(
                        (acc: any, value: any, index: any) => {
                          acc[index] = value;
                          return acc;
                        },
                        {},
                      );
                      return (
                        <div
                          className='flex border border-solid border-inherit mb-[20px] rounded-[6px] p-[20px]'
                          key={field.id}
                        >
                          <div className='w-full'>
                            <FormItem
                              label={commonUI.headerName}
                              required
                              validateStatus={
                                object?.[index]?.headerName ? 'error' : ''
                              }
                              help={
                                <>
                                  {(object?.[index] as any)?.headerName &&
                                    (object?.[index] as any)?.headerName
                                      ?.message}
                                </>
                              }
                            >
                              <Controller
                                name={`headers.${index}.headerName`}
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    maxLength={100}
                                    field={field}
                                  />
                                )}
                              />
                            </FormItem>
                            <FormItem
                              label={commonUI.headerValue}
                              required
                              validateStatus={
                                object?.[index]?.headerValue ? 'error' : ''
                              }
                              help={
                                <>
                                  {(object?.[index] as any)?.headerValue &&
                                    (object?.[index] as any)?.headerValue
                                      .message}
                                </>
                              }
                            >
                              <Controller
                                name={`headers.${index}.headerValue`}
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    maxLength={100}
                                    field={field}
                                  />
                                )}
                              />
                            </FormItem>
                          </div>
                          <div>
                            <CloseOutlined
                              onClick={() => {
                                removeHeader(index);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className='flex gap-4 mt-[30px] w-[130px]'>
                      <Button
                        type='primary'
                        onClick={() =>
                          appendHeader({
                            headerName: '',
                            headerValue: '',
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        {commonUI.btnAddHeader}
                      </Button>
                    </div>
                  </>
                </FormItem>
              </div>
            </div>
            <div className='flex gap-4 w-full mt-[30px] w-[80px]'>
              <Button
                onClick={() => navigate(APP_ROUTER.WEBHOOK.DETAIL)}
                type='primary'
                ghost
                icon={<LeftCircleOutlined />}
              >
                {commonUI.back}
              </Button>
            </div>
          </div>
          <Modal
            open={open}
            onCancel={() => setOpen(false)}
            title={commonUI.confirm}
            description={messageUI.update}
          >
            <div className='flex justify-center gap-[10px] p-[20px] bg-[#E6FAFD]'>
              <Button
                onClick={() => setOpen(false)}
                className='w-full lg:w-[200px] bg-[#D6D9E0] hover:!bg-[#D9DDE6] text-gray-500 hover:!text-gray-500'
                type='primary'
              >
                {commonUI.no}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                className='w-full lg:w-[200px]'
                type='primary'
                disabled={mutationUpdate.isPending}
              >
                {commonUI.yes}
              </Button>
            </div>
          </Modal>
        </Form>
      </FormProvider>
    </div>
  );
};

export default FormWebhook;
