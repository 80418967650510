import { messageUI } from '@/utils/text/message';
import dayjs from 'dayjs';
import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    companyId: yup.number().required(messageUI.companyRequired),
    startAt: yup.string().required(messageUI.startDateRequired),
    expiredAt: yup
      .string()
      .required(messageUI.endDateRequired)
      .test('isGreater', messageUI.expirationInvalid, function (value) {
        const { startAt } = this.parent;
        if (!startAt) return true;
        return dayjs(value).isAfter(dayjs(startAt));
      }),
  } as any);
