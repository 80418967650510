import { Avatar, Upload } from 'antd';

export const UploadImage = ({}: any) => {
  return (
    <div className='flex justify-center'>
      <Upload
        name='avatar'
        listType='picture-card'
        showUploadList={false}
        accept='.jpg,.jpeg,.png'
      >
        <div>
          <figure>
            <Avatar
              style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
              size='large'
              gap={2}
            ></Avatar>
          </figure>
        </div>
      </Upload>
    </div>
  );
};
