import { getCookie, KeyCookie } from '@/utils/storage';
import { SET_IS_AUTHENTICATED, SET_NAME } from './constants';

const initState: any = {
  isAuthenticated: getCookie(KeyCookie.accessToken) || '',
  name: getCookie(KeyCookie.currentUser)
    ? JSON.parse(getCookie(KeyCookie.currentUser)).name
    : '',
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
  }
}

export { initState };
export default reducer;
