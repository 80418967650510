import { Button } from '@/components/common';
import { ROLE_KEY } from '@/const/option';
import { getCookie, KeyCookie } from '@/utils/storage';
import {
  LeftOutlined,
  RightOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';
import { Drawer, Layout, Menu } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTER } from '@/routes/routes';
import { commonUI } from '@/utils/text/UI';
import {
  ApartmentOutlined,
  PieChartOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import './style.scss';

interface IProps {
  collapsed: boolean;
  openDraw: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDraw: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarComponent: React.FC<IProps> = ({
  collapsed,
  openDraw,
  setCollapsed,
  setOpenDraw,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState<string>('/');

  const role = getCookie(KeyCookie.role)
    ? JSON.parse(getCookie(KeyCookie.role))
    : 'GUEST';

  const user = getCookie(KeyCookie.currentUser);
  let parsedUser: any = {};
  if (user) {
    parsedUser = JSON.parse(user);
  }

  const handleOnClickMenu = async (e: { key: string }) => {
    navigate(e.key);
    setCurrent(e.key);
  };

  useEffect(() => {
    setCurrent('/' + location.pathname.split('/')[1]);

    if (
      parsedUser?.role === ROLE_KEY.COMPANY &&
      location.pathname.includes('/company')
    ) {
      setCurrent(
        APP_ROUTER.COMPANY.DETAIL.replace(
          ':id',
          String(parsedUser?.company?.id),
        ),
      );
    }
  }, [location]);
  const menu =
    role === ROLE_KEY.ADMIN
      ? [
          {
            key: APP_ROUTER.SCORE_INDEX.LIST,
            icon: <CalculatorOutlined />,
            label: commonUI.scoreIndex,
          },
          {
            key: APP_ROUTER.CONTRACT.LIST,
            icon: <SnippetsOutlined />,
            label: commonUI.contract,
          },
          {
            key:
              parsedUser?.role === ROLE_KEY.ADMIN
                ? APP_ROUTER.COMPANY.LIST
                : APP_ROUTER.COMPANY.DETAIL.replace(
                    ':id',
                    String(parsedUser?.userId),
                  ),
            icon: <ApartmentOutlined />,
            label: commonUI.company,
          },
        ]
      : [
          {
            key: APP_ROUTER.CONTRACT.LIST,
            icon: <SnippetsOutlined />,
            label: commonUI.contract,
          },
          {
            key:
              parsedUser?.role === ROLE_KEY.ADMIN
                ? APP_ROUTER.COMPANY.LIST
                : APP_ROUTER.COMPANY.DETAIL.replace(
                    ':id',
                    String(parsedUser?.userId),
                  ),
            icon: <ApartmentOutlined />,
            label: commonUI.company,
          },
          {
            key: APP_ROUTER.WEBHOOK.DETAIL,
            icon: <PieChartOutlined />,
            label: commonUI.webhook,
          },
        ];

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={clsx('c-sidebar', 'no-select', {
        'c-sidebar-collapsed': collapsed,
      })}
    >
      <Button
        className='collapse-btn'
        shape='circle'
        icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
        onClick={() => setCollapsed((prev) => !prev)}
      />
      <Drawer
        placement='left'
        width={250}
        onClose={() => setOpenDraw(false)}
        open={openDraw}
        closable={false}
        closeIcon={<></>}
        classNames={{
          body: 'c-drawer-sidebar__body',
        }}
      >
        <Menu
          className='menu'
          onClick={handleOnClickMenu}
          mode='inline'
          items={menu}
          selectedKeys={[current]}
          rootClassName='c-menu'
        />
      </Drawer>
      <Menu
        className='menu'
        onClick={handleOnClickMenu}
        mode='inline'
        items={menu}
        selectedKeys={[current]}
        rootClassName='c-menu c-menu--main'
      />
    </Layout.Sider>
  );
};

export default SidebarComponent;
