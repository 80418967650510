import { Navigate, Outlet } from 'react-router-dom';

interface RoleBasedRouteProps {
  isAuthenticated: boolean;
  allowedRoles: string[];
  userRole: string;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
  isAuthenticated,
  allowedRoles,
  userRole,
}) => {
  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to='/403' replace />;
  }

  return <Outlet />;
};

export default RoleBasedRoute;
