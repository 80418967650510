export const MESSAGE = {
  CREATE_SUCCESS: 'Create success',
  UPDATE_SUCCESS: 'Update success',
  CREATE_FAIL: 'Create fail',
  UPDATE_FAIL: 'Update fail',
  SERVER_ERROR: 'エラーが発生しました。再度お試しください。',
  LOGIN_SUCCESS: 'Login success',
  FORGOT_PASSWORD_SUCCESS:
    'リンク付きのメールが送信されました。メールを確認してください。',
  RESET_PASSWORD_SUCCESS: 'パスワードのリセットに成功しました。',
  CHANGE_PASSWORD_SUCCESS: 'Changed password success',
  EXPIRED_CODE: 'The verification code has expired',
  OLD_PASSWORD_INCORRECT: 'Old password incorrect',
  EMPTY_VALUE: 'cannot be empty or whitespace only',
};
export const ErrorMessage = {
  SERVER_ERROR: 'エラーが発生しました。再度お試しください。',
  INVALID:
    'Invalid input data. Please check the provided information and try again.',
  EMAIL_CONFLICT: '代理者のメールアドレスが既に存在しています。',
  EXPIRED_CODE:
    'このリンクは使用済みか、期限切れです。別のリンクをお試しください。',
  PASSWORD_TO_WEAK: 'Password too weak',
  USER_NOT_FOUND: '正しいメールアドレスを入力してください。',
  INCORRECT_LOGIN: 'メールアドレスまたはパスワードが正しくありません。',
  ACCOUNT_INVALID:
    '会社が無効になっています。担当者にアカウントを再度有効にするようにご連絡ください。',
  NAME_INVALID: 'The name is not in the correct format',
  GAME_NOT_FOUND: 'Game not found',
  TOKEN_INVALID: 'token invalid',
  TOKEN_EXPIRED: 'Token expired',
  ROLE_NOT_FOUND: 'Role not found',
  USER_ID_INVALID: 'User id invalid',
  WRONG_PASSWORD: '古いパスワードが正しくありません。',
  COMPANY_NOT_FOUND: '会社が無効になっています。',
  CONTRACT_NOT_FOUND: '契約が有効になっていますので、編集できません。',
  SCORE_INDEX_NOT_FOUND: 'Score index not exist',
  CONTRACT_IS_INVALID: 'Contract date must be after the expiration date',
  CHANGE_TOKEN_INVALID: 'このトークンが再設定されました。',
  APPLY_TOKEN_INVALID: 'このトークンが承認されました。',
} as const;

export type ErrorMessageKeys = keyof typeof ErrorMessage;
