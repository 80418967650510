import { Spin as SpinBase, SpinProps } from 'antd';
import { clsx } from 'clsx';
import './style.scss';

interface IProps extends SpinProps {
  children?: React.ReactNode;
}
export const Spin: React.FC<IProps> = ({ className, children, ...props }) => {
  return (
    <SpinBase rootClassName={clsx('custom-spin', className)} {...props}>
      {children}
    </SpinBase>
  );
};
