import { UserApi } from '@/apis';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Form, DatePicker, Input, Row, Select } from 'antd';
import { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { regex } from '@/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ErrorMessage, ErrorMessageKeys, MESSAGE } from '@/const/message';
import { Button, Field, Spin } from '@/components/common';
import { convertObjectToArray } from '@/utils/helpers';
import { genderOptions } from '@/const/option';
import { commonUI } from '@/utils/text/UI';
import { messageUI } from '@/utils/text/message';
import { ENDPOINT } from '@/apis/endpoint';
import { getCookie, KeyCookie, setCookie } from '@/utils/storage';
import { actions, useStore } from '@/store';

interface UserForm {
  fullName: string;
  email: string;
  address: string;
  phone: string;
  dob: Dayjs;
  gender: string;
}

const UserInfo: React.FC = () => {
  const [, dispatch] = useStore();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [ENDPOINT.USER.PROFILE],
    queryFn: async () => {
      try {
        const response = await UserApi.getProfile();
        return response;
      } catch (error: any) {
        toastMessage(
          NotificationType.error,
          ErrorMessage[error.error as ErrorMessageKeys] ??
            getMessageSubmitForm(error as any),
        );
        throw error;
      }
    },
    retry: false,
  });

  const validationSchema = Yup.object({
    fullName: Yup.string().trim().required(messageUI.fullNameRequired),
    phone: Yup.string()
      .nullable()
      .trim()
      .test('phoneInvalid', messageUI.phoneInvalid, (value) => {
        if (!value) return true;
        return regex.validatePhone.test(value);
      }),
  });
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset, watch } = methods;

  const mutation = useMutation({
    mutationFn: UserApi.updateInfo,
    onSuccess: async () => {
      const currentUser = getCookie(KeyCookie.currentUser)
        ? {
            ...JSON.parse(getCookie(KeyCookie.currentUser)),
            name: watch('fullName'),
          }
        : '';
      setCookie(KeyCookie.currentUser, JSON.stringify(currentUser));
      dispatch(actions.setName(watch('fullName')));
      refetch();
      toastMessage(NotificationType.success, messageUI.updateProfileSuccess);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });
  const onSubmit = async (values: UserForm) => {
    const dataForm = {
      ...values,
      fullName: values.fullName,
      address: values.address,
      phone: values.phone,
      gender: values.gender,
      ...(values.dob
        ? { dob: values.dob.format('YYYY-MM-DD') }
        : { dob: null }),
    };
    mutation.mutate(dataForm);
  };

  useEffect(() => {
    if (data) {
      const value = {
        fullName: data.fullName,
        email: data.email,
        address: data.address,
        phone: data.phone,
        gender: data.gender,
        dob: data.dob !== null ? dayjs(data.dob, 'YYYY-MM-DD') : null,
      };
      reset(value);
    }
  }, [data]);

  return (
    <div className='h-full'>
      <FormProvider {...methods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='h-full w-full flex flex-col items-center'
          layout='vertical'
        >
          <div className='w-full bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='text-2xl font-semibold'>{commonUI.info}</div>
          </div>
          <div className='w-full h-full bg-white p-6 rounded-lg py-[32px] px-[24px]'>
            <Row gutter={[24, 4]} className='w-full'>
              <Col span={24}>
                <Field name='fullName' label={commonUI.fullName} required>
                  <Input maxLength={60} />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='email' label={commonUI.email} required>
                  <Input disabled={true} />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='address' label={commonUI.address}>
                  <Input
                    placeholder={messageUI.placeholderInput}
                    maxLength={200}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='phone' label={commonUI.phone}>
                  <Input
                    placeholder={messageUI.placeholderInput}
                    maxLength={11}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='gender' label={commonUI.gender}>
                  <Select options={convertObjectToArray(genderOptions)} />
                </Field>
              </Col>
              <Col span={24}>
                <Field name='dob' label={commonUI.birthday}>
                  <DatePicker
                    placeholder={messageUI.placeholderSelect}
                    format='YYYY-MM-DD'
                    className='w-full'
                    disabledDate={(current) => current > dayjs().endOf('day')}
                  />
                </Field>
              </Col>
              <Col span={24}>
                <div className='flex justify-center gap-4'>
                  <div className='w-[240px]'>
                    <Button
                      className='tracking-[-1.5px]'
                      block={true}
                      type='primary'
                      htmlType='submit'
                    >
                      {commonUI.update}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </FormProvider>
      {(isLoading || mutation.isPending) && <Spin />}
    </div>
  );
};

export default UserInfo;
