import { Form, FormItemProps, Tag } from 'antd';
import './style.scss';

export const FormItem: React.FC<FormItemProps> = ({
  className,
  colon = false,
  children,
  label,
  required,
  ...props
}) => {
  return (
    <Form.Item
      colon={colon}
      className={className}
      {...props}
      label={
        <div className='flex'>
          <div className='text-nowrap'>{label}</div>
          <div className='flex justify-center items-center'>
            {required && <Tag className='p-[2px] ml-[5px]' color='#FF4D4F' />}
          </div>
        </div>
      }
    >
      {children}
    </Form.Item>
  );
};
