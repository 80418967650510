import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

class ScoreIndex {
  getAll(params: object | undefined) {
    return axiosClient
      .get(ENDPOINT.SCORE_INDEX.GET_ALL, { params })
      .then((res) => res.data.data);
  }
  updateScoreIndex(data: { id: number; value: string }[]) {
    return axiosClient
      .put(ENDPOINT.SCORE_INDEX.GET_ALL, data)
      .then((res) => res.data.data);
  }
}

export const ScoreIndexApi = new ScoreIndex();
