import { regex } from '@/utils';
import { messageUI } from '@/utils/text/message';
import * as yup from 'yup';

export const formSchemaFn = () =>
  yup.object().shape({
    name: yup.string().trim().required(messageUI.companyNameRequired),
    postCode: yup
      .string()
      .nullable()
      .trim()
      .test('postcodeInvalid', messageUI.postcodeInvalid, (value) => {
        if (!value) return true;
        return regex.validatePostCode.test(value);
      }),
    finishUrl: yup
      .string()
      .trim()
      .required(messageUI.finishUrlRequired)
      .test('finishUrlInvalid', messageUI.finishUrlInvalid, (value) => {
        if (!value) return true;
        return regex.validateLink.test(value);
      }),
    fax: yup
      .string()
      .nullable()
      .trim()
      .test('faxInvalid', messageUI.faxInvalid, (value) => {
        if (!value) return true;
        return regex.validatePostCode.test(value);
      }),
    email: yup
      .string()
      .trim()
      .required(messageUI.emailRequired)
      .matches(regex.validateEmail, messageUI.emailInvalid),
    phone: yup
      .string()
      .nullable()
      .trim()
      .test('phoneInvalid', messageUI.phoneInvalid, (value) => {
        if (!value) return true;
        return regex.validatePhone.test(value);
      }),
    address: yup.string().trim().required(messageUI.addressRequired),
    fullNameUser: yup.string().trim().required(messageUI.fullNameRequired),
    genderUser: yup.number().required(messageUI.required),
    emailUser: yup
      .string()
      .trim()
      .required(messageUI.emailRequired)
      .matches(regex.validateEmail, messageUI.emailInvalid),
    phoneUser: yup
      .string()
      .nullable()
      .trim()
      .test('phoneInvalid', messageUI.phoneInvalid, (value) => {
        if (!value) return true;
        return regex.validatePhone.test(value);
      }),
  } as any);
