import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import '@/styles/global.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StoreProvider } from './store';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/es/locale/ja_JP';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StoreProvider>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={jaJP}
        theme={{
          token: {
            colorPrimary: '#3BB8C6',
          },
        }}
      >
        <App />
      </ConfigProvider>
    </QueryClientProvider>
  </StoreProvider>,
);
