import { CompanyApi, ValuesForm } from '@/apis';
import {
  Button,
  DatePicker,
  Field,
  Input,
  Modal,
  Select,
  Spin,
} from '@/components/common';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Divider, Form, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, ErrorMessageKeys, MESSAGE } from '@/const/message';
import { ROLE_KEY, companyStatusLabels, genderOptions } from '@/const/option';
import { convertObjectToArray } from '@/utils/helpers';
import { getCookie, KeyCookie, setCookie } from '@/utils/storage';
import { APP_ROUTER } from '@/routes/routes';
import { LeftCircleOutlined } from '@ant-design/icons';
import { formSchemaFn } from './formSchemaFn';
import { yupResolver } from '@hookform/resolvers/yup';
import { ENDPOINT } from '@/apis/endpoint';
import { commonUI } from '@/utils/text/UI';
import { messageUI } from '@/utils/text/message';
import { actions, useStore } from '@/store';

interface UserForm {
  name: string;
  email: string;
  address: string;
  description: string;
  representative: string;
  postCode: string;
  fax: string;
  phone: string;
  code: string;
  status?: string;
  finishUrl?: string;
  emailUser: string;
  phoneUser: string;
  fullNameUser: string;
  genderUser: number;
  addressUser: string;
  dobUser: string;
  token?: string;
}

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(weekday);

const FormCompany: React.FC = () => {
  const [, dispatch] = useStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const role = getCookie(KeyCookie.role)
    ? JSON.parse(getCookie(KeyCookie.role))
    : 'GUEST';

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(formSchemaFn()),
  });

  const finishURL = process.env.REACT_APP_FINISH_URL || '';
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = methods;

  const { data: dataDetailCompany, isLoading } = useQuery({
    queryKey: [ENDPOINT.COMPANY.GET_DETAIL, Number(id)],
    queryFn: async () => {
      try {
        const response = await CompanyApi.getDetailCompany(Number(id));
        return response;
      } catch (error: any) {
        toastMessage(
          NotificationType.error,
          ErrorMessage[error.error as ErrorMessageKeys] ??
            getMessageSubmitForm(error as any),
        );
        throw error;
      }
    },
    enabled: !!id,
    retry: false,
  });

  useEffect(() => {
    if (dataDetailCompany) {
      const importDataForm = {
        ...dataDetailCompany,
        emailUser: dataDetailCompany.users[0]?.email,
        phoneUser: dataDetailCompany.users[0]?.phone,
        fullNameUser: dataDetailCompany.users[0]?.fullName,
        genderUser: dataDetailCompany.users[0]?.gender,
        addressUser: dataDetailCompany.users[0]?.address,
        dobUser:
          dataDetailCompany.users[0]?.dob !== null &&
          dayjs(dataDetailCompany.users[0]?.dob, 'YYYY-MM-DD'),
      };
      reset(importDataForm);
    }
  }, [dataDetailCompany, reset]);

  const mutationCreate = useMutation({
    mutationFn: CompanyApi.createCompany,
    onSuccess: async () => {
      navigate(APP_ROUTER.COMPANY.LIST);
      toastMessage(NotificationType.success, messageUI.createSuccess);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: CompanyApi.updateCompany,
    onSuccess: async () => {
      if (role === ROLE_KEY.COMPANY) {
        const currentUser = getCookie(KeyCookie.currentUser)
          ? {
              ...JSON.parse(getCookie(KeyCookie.currentUser)),
              name: watch('fullNameUser'),
            }
          : '';
        setCookie(KeyCookie.currentUser, JSON.stringify(currentUser));
        dispatch(actions.setName(watch('fullNameUser')));
      }
      setOpen(false);
      navigate(APP_ROUTER.COMPANY.DETAIL.replace(':id', String(id)));
      toastMessage(NotificationType.success, messageUI.updateSuccess);
    },
    onError: async (error: { error: string }) => {
      setOpen(false);
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const onSubmit = async (values: UserForm) => {
    const data: ValuesForm = {
      name: values.name,
      email: values.email,
      address: values.address,
      phone: values.phone,
      fax: values.fax,
      postCode: values.postCode,
      status: values.status,
      finishUrl: values.finishUrl,
      users: [
        {
          fullName: values.fullNameUser,
          email: values.emailUser,
          address: values.addressUser,
          phone: values.phoneUser,
          ...(values.dobUser
            ? { dob: dayjs(values.dobUser).format('YYYY-MM-DD') }
            : { dob: null }),
          gender: values.genderUser,
        },
      ],
    };
    if (id) {
      data.id = Number(id);
    }
    id ? mutationUpdate.mutate(data) : mutationCreate.mutate(data);
  };

  useEffect(() => {
    if (!id) {
      setValue('genderUser', 0);
      setValue('finishUrl', `${finishURL}/close-app`);
    }
  }, []);

  return (
    <div>
      <FormProvider {...methods}>
        <Form
          onFinish={handleSubmit(onSubmit)}
          className='w-full h-full flex flex-col'
          labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
          labelAlign='left'
          layout='vertical'
        >
          <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='text-2xl font-semibold'>
              {id ? commonUI.updateCompany : commonUI.createCompany}
            </div>
          </div>
          <div className='w-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
            <div>
              <div className='flex justify-between'>
                <Typography.Title level={4} className='flex'>
                  <div className='mb-4'>{commonUI.infoCompany}</div>
                </Typography.Title>
                {id && (
                  <div onClick={() => isValid && setOpen(true)}>
                    <Button
                      type='primary'
                      block={true}
                      htmlType={!isValid ? 'submit' : 'button'}
                      className='w-full max-w-xs tracking-[-1.5px]'
                    >
                      {commonUI.update}
                    </Button>
                  </div>
                )}
                {!id && (
                  <div>
                    <Button
                      htmlType='submit'
                      type='primary'
                      block={true}
                      className='w-full max-w-xs tracking-[-1.5px]'
                    >
                      {commonUI.create}
                    </Button>
                  </div>
                )}
              </div>
              <Row gutter={[64, 0]}>
                <Col xs={24} lg={12}>
                  <Field name='name' label={commonUI.companyName} required>
                    <Input
                      placeholder={messageUI.placeholderInput}
                      maxLength={100}
                    />
                  </Field>
                </Col>
                <Col xs={24} lg={12}>
                  <Field name='postCode' label={commonUI.companyPostCode}>
                    <Input
                      placeholder={messageUI.placeholderInput}
                      maxLength={8}
                    />
                  </Field>
                </Col>
                <Col xs={24} lg={12}>
                  <Field name='email' label={commonUI.email} required>
                    <Input
                      placeholder={messageUI.placeholderInput}
                      disabled={!!id}
                      maxLength={64}
                    />
                  </Field>
                </Col>
                <Col xs={24} lg={12}>
                  <Field name='phone' label={commonUI.companyPhone}>
                    <Input
                      placeholder={messageUI.placeholderInput}
                      maxLength={11}
                    />
                  </Field>
                </Col>
                <Col xs={24} lg={12}>
                  <Field name='address' label={commonUI.address} required>
                    <Input
                      placeholder={messageUI.placeholderInput}
                      maxLength={200}
                    />
                  </Field>
                </Col>
                <Col xs={24} lg={12}>
                  <Field name='fax' label={commonUI.companyFax}>
                    <Input
                      placeholder={messageUI.placeholderInput}
                      maxLength={20}
                    />
                  </Field>
                </Col>
                <Col xs={24} lg={12}>
                  {id && role === ROLE_KEY.ADMIN && (
                    <Field name='status' label={commonUI.status} required>
                      <Select
                        options={convertObjectToArray(
                          companyStatusLabels,
                          'string',
                        )}
                      />
                    </Field>
                  )}
                </Col>
              </Row>
              <div>
                <div className='flex justify-between'>
                  <Typography.Title level={4}>
                    <div className='mb-4'>{commonUI.infoRep}</div>
                  </Typography.Title>
                </div>
                <Row gutter={[64, 4]}>
                  <Col xs={24} lg={12}>
                    <Field
                      name='fullNameUser'
                      label={commonUI.fullName}
                      required
                    >
                      <Input
                        placeholder={messageUI.placeholderInput}
                        maxLength={60}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='genderUser' label={commonUI.gender}>
                      <Select
                        placeholder={messageUI.placeholderSelect}
                        options={convertObjectToArray(genderOptions)}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='emailUser' label={commonUI.email} required>
                      <Input
                        placeholder={messageUI.placeholderInput}
                        disabled={!!id}
                        maxLength={64}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='addressUser' label={commonUI.address}>
                      <Input
                        placeholder={messageUI.placeholderInput}
                        maxLength={200}
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='dobUser' label={commonUI.birthday}>
                      <DatePicker
                        placeholder={messageUI.placeholderSelect}
                        format='YYYY-MM-DD'
                        className='w-full'
                        disabledDate={(current) =>
                          current > dayjs().endOf('day')
                        }
                      />
                    </Field>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Field name='phoneUser' label={commonUI.phone}>
                      <Input
                        placeholder={messageUI.placeholderInput}
                        maxLength={11}
                      />
                    </Field>
                  </Col>
                </Row>
              </div>
              <div>
                <div className='flex justify-between'>
                  <Typography.Title level={4} className='flex'>
                    <div className='mb-4'>{commonUI.infoUrl}</div>
                  </Typography.Title>
                </div>
                <Row gutter={[64, 0]}>
                  <Col xs={24} lg={12}>
                    <Field name='finishUrl' label={commonUI.finishUrl} required>
                      <Input
                        placeholder={`${finishURL}/close-app`}
                        maxLength={100}
                      />
                    </Field>
                  </Col>
                </Row>
              </div>
            </div>
            <div className='flex gap-4 w-full mt-[30px] w-[80px]'>
              <Button
                onClick={() =>
                  id
                    ? navigate(
                        APP_ROUTER.COMPANY.DETAIL.replace(':id', String(id)),
                      )
                    : navigate(APP_ROUTER.COMPANY.LIST)
                }
                type='primary'
                ghost
                icon={<LeftCircleOutlined />}
              >
                {commonUI.back}
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title={commonUI.confirm}
        description={messageUI.update}
      >
        <div className='flex justify-center gap-[10px] p-[20px] bg-[#E6FAFD]'>
          <Button
            onClick={() => setOpen(false)}
            className='w-full lg:w-[200px] bg-[#D6D9E0] hover:!bg-[#D9DDE6] text-gray-500 hover:!text-gray-500'
            type='primary'
          >
            {commonUI.no}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className='w-full lg:w-[200px]'
            type='primary'
            disabled={mutationUpdate.isPending}
          >
            {commonUI.yes}
          </Button>
        </div>
      </Modal>
      {(isLoading || mutationCreate.isPending || mutationUpdate.isPending) && (
        <Spin />
      )}
    </div>
  );
};

export default FormCompany;
