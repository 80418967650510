export const gender = [
  { value: '', label: 'Other' },
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
];

export const statusColors = {
  ACTIVE: '#43C486',
  EXPIRED: '#880F1D',
  IN_ACTIVE: '#595959',
};

export const statusLabels = {
  ACTIVE: '期間中',
  IN_ACTIVE: '未開始',
  EXPIRED: '終了',
};

export const statusTokenLabels = {
  ACCEPT: '承認済み',
  WAIT: '承認待ち',
};

export const companyStatusLabels = {
  ACTIVE: '有効',
  IN_ACTIVE: '無効',
};

export const isFlagOptions = {
  1: '有効',
  0: '無効',
};

export const companyStatusColors = {
  ACTIVE: '#CBF2DC',
  IN_ACTIVE: '#FFF5EC',
};

export const genderOptions = {
  0: '女',
  1: '男',
  2: 'その他',
};

export const statusCompany = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'IN_ACTIVE', label: 'IN_ACTIVE' },
];

export const taskKeyLabels = {
  JUST_FIT: 'ジャストフィット',
  KENTOSHIKI: 'タイムチェック',
  FLASH_LIGHT: 'フラッシュライト',
  ROUTE_99: 'ルート99',
  TRAIL: 'ビジュアルサーチ',
};

export const taskScoreColors = {
  FLASH_LIGHT: 'cyan',
  KENTOSHIKI: 'green',
  JUST_FIT: 'gold',
  ROUTE_99: 'orange',
  TRAIL: 'volcano',
};

export const formatDate = {
  FULL_DATE: 'YYYY[年]MM[月]DD[日]',
  FULL_TIME: 'YYYY[年]MM[月]DD[日] HH[時]mm[分]ss[秒]',
};

export enum ROLE_KEY {
  'ADMIN' = 'ADMIN',
  'COMPANY' = 'COMPANY',
}

export enum STATUS_KEY {
  'IN_ACTIVE' = 'IN_ACTIVE',
  'ACTIVE' = 'ACTIVE',
  'EXPIRED' = 'EXPIRED',
}

export enum TYPE_SCORE_KEY {
  'HyojunHensa' = 'HyojunHensa',
  'HyojunTen' = 'HyojunTen',
}
