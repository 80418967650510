import { Select, TableColumnsType, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  Table,
  Pagination,
  DataEmpty,
  Spin,
} from '@/components/common';
import { ContractApi } from '@/apis/contract';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getCookie, KeyCookie } from '@/utils/storage';
import {
  ROLE_KEY,
  statusColors,
  STATUS_KEY,
  statusLabels,
  statusTokenLabels,
  formatDate,
} from '@/const/option';
import { PlusOutlined, EyeFilled } from '@ant-design/icons';
import { convertObjectToArray } from '@/utils/helpers';
import { SearchOutlined } from '@ant-design/icons';
import { APP_ROUTER } from '@/routes/routes';
import { ENDPOINT } from '@/apis/endpoint';
import { commonUI } from '@/utils/text/UI/index';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import './style.scss';

interface IParams {
  name: string;
  status: string | undefined;
  pageSize: number;
  page: number;
}

const ManagerContract: React.FC = () => {
  const navigate = useNavigate();
  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState<IParams>({
    name: '',
    status: undefined,
    pageSize: 10,
    page: 1,
  });

  const { data, isLoading } = useQuery({
    queryKey: [ENDPOINT.CONTRACT.GET_LIST, params],
    queryFn: async () => {
      try {
        const response = await ContractApi.getListContract(params);
        return response;
      } catch (error: any) {
        toastMessage(
          NotificationType.error,
          ErrorMessage[error.error as ErrorMessageKeys] ??
            getMessageSubmitForm(error as any),
        );
        throw error;
      }
    },
  });

  const onChangePagination = (page: number, pageSize: number) => {
    setParams({ ...params, page, pageSize });
  };
  const role = getCookie(KeyCookie.role)
    ? JSON.parse(getCookie(KeyCookie.role))
    : '';

  const columns: TableColumnsType =
    role === ROLE_KEY.ADMIN
      ? [
          {
            title: commonUI.contractCode,
            dataIndex: 'code',
            width: '80px',
          },
          {
            title: commonUI.companyName,
            width: '110px',
            render: (record: { company: { name: string } }) => {
              return <div className='truncate'>{record.company?.name}</div>;
            },
          },
          {
            title: commonUI.startDate,
            dataIndex: 'startAt',
            width: '60px',
            render: (startAt: string) => {
              return dayjs(startAt).format(formatDate.FULL_DATE);
            },
          },
          {
            title: commonUI.endDate,
            dataIndex: 'expiredAt',
            width: '60px',
            render: (expiredAt: string) => {
              return dayjs(expiredAt).format(formatDate.FULL_DATE);
            },
          },
          {
            title: commonUI.status,
            dataIndex: 'status',
            width: '50px',
            render: (status: string) => {
              return (
                <Tag color={statusColors[status as keyof typeof statusColors]}>
                  {statusLabels[status as keyof typeof statusLabels]}
                </Tag>
              );
            },
          },
          {
            title: commonUI.tokenNormal,
            width: '70px',
            render: (item: any) => {
              return (
                <>
                  {item?.status === STATUS_KEY.ACTIVE ? (
                    <>
                      {item.tokenTmp ? (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.WAIT}
                        </Tag>
                      ) : (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.ACCEPT}
                        </Tag>
                      )}
                    </>
                  ) : (
                    <>
                      <Tag
                        color={
                          statusColors[
                            item?.status as keyof typeof statusColors
                          ]
                        }
                      >
                        {
                          statusLabels[
                            item?.status as keyof typeof statusLabels
                          ]
                        }
                      </Tag>
                    </>
                  )}
                </>
              );
            },
          },
          {
            title: commonUI.tokenMaster,
            width: '70px',
            render: (item: any) => {
              return (
                <>
                  {item?.status === STATUS_KEY.ACTIVE ? (
                    <>
                      {item.tokenMasterTmp ? (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.WAIT}
                        </Tag>
                      ) : (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.ACCEPT}
                        </Tag>
                      )}
                    </>
                  ) : (
                    <>
                      <Tag
                        color={
                          statusColors[
                            item?.status as keyof typeof statusColors
                          ]
                        }
                      >
                        {
                          statusLabels[
                            item?.status as keyof typeof statusLabels
                          ]
                        }
                      </Tag>
                    </>
                  )}
                </>
              );
            },
          },
          {
            render: (record: { id: number }) => {
              return (
                <div>
                  <Link
                    to={APP_ROUTER.CONTRACT.DETAIL.replace(
                      ':id',
                      String(record.id),
                    )}
                  >
                    <Button type='primary' icon={<EyeFilled />}>
                      {commonUI.detail}
                    </Button>
                  </Link>
                </div>
              );
            },
            fixed: 'right',
            align: 'center',
            width: '50px',
          },
        ]
      : [
          {
            title: commonUI.contractCode,
            dataIndex: 'code',
            width: '80px',
          },
          {
            title: commonUI.startDate,
            dataIndex: 'startAt',
            width: '60px',
            render: (startAt: string) => {
              return dayjs(startAt).format(formatDate.FULL_DATE);
            },
          },
          {
            title: commonUI.endDate,
            dataIndex: 'expiredAt',
            width: '60px',
            render: (expiredAt: string) => {
              return dayjs(expiredAt).format(formatDate.FULL_DATE);
            },
          },
          {
            title: commonUI.status,
            dataIndex: 'status',
            width: '50px',
            render: (status: string) => {
              return (
                <Tag color={statusColors[status as keyof typeof statusColors]}>
                  {statusLabels[status as keyof typeof statusLabels]}
                </Tag>
              );
            },
          },
          {
            title: commonUI.tokenNormal,
            width: '70px',
            render: (item: any) => {
              return (
                <>
                  {item?.status === STATUS_KEY.ACTIVE ? (
                    <>
                      {item.tokenTmp ? (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.WAIT}
                        </Tag>
                      ) : (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.ACCEPT}
                        </Tag>
                      )}
                    </>
                  ) : (
                    <>
                      <Tag
                        color={
                          statusColors[
                            item?.status as keyof typeof statusColors
                          ]
                        }
                      >
                        {
                          statusLabels[
                            item?.status as keyof typeof statusLabels
                          ]
                        }
                      </Tag>
                    </>
                  )}
                </>
              );
            },
          },
          {
            title: commonUI.tokenMaster,
            width: '70px',
            render: (item: any) => {
              return (
                <>
                  {item?.status === STATUS_KEY.ACTIVE ? (
                    <>
                      {item.tokenMasterTmp ? (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.WAIT}
                        </Tag>
                      ) : (
                        <Tag
                          color={
                            statusColors[
                              item?.status as keyof typeof statusColors
                            ]
                          }
                        >
                          {statusTokenLabels.ACCEPT}
                        </Tag>
                      )}
                    </>
                  ) : (
                    <>
                      <Tag
                        color={
                          statusColors[
                            item?.status as keyof typeof statusColors
                          ]
                        }
                      >
                        {
                          statusLabels[
                            item?.status as keyof typeof statusLabels
                          ]
                        }
                      </Tag>
                    </>
                  )}
                </>
              );
            },
          },
          {
            render: (record: { id: number }) => {
              return (
                <div>
                  <Link
                    to={APP_ROUTER.CONTRACT.DETAIL.replace(
                      ':id',
                      String(record.id),
                    )}
                  >
                    <Button type='primary' icon={<EyeFilled />}>
                      {commonUI.detail}
                    </Button>
                  </Link>
                </div>
              );
            },
            fixed: 'right',
            align: 'center',
            width: '50px',
          },
        ];

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setParams({ ...params, name: search, page: 1 });
    }, 500);
  }, [search]);

  return (
    <div>
      <div className='bg-white px-[24px] py-[16px] rounded-lg mb-[10px]'>
        <div className='flex flex-col justify-between gap-4 md:flex-row'>
          <div className='w-full text-2xl font-semibold truncate'>
            {commonUI.listContract}
          </div>
          <div className='flex flex-col md:flex-row gap-[10px] justify-end w-full'>
            {role === ROLE_KEY.ADMIN && (
              <Input
                allowClear
                className='w-full md:w-[260px]'
                placeholder={commonUI.companyName + '&' + commonUI.contractCode}
                prefix={<SearchOutlined />}
                onChange={(e) => setSearch(e.target.value.trim())}
              />
            )}
            <Select
              allowClear
              className='w-full md:w-[200px]'
              placeholder={commonUI.status}
              onChange={(value) =>
                setParams({ ...params, status: value, page: 1 })
              }
              options={convertObjectToArray(statusLabels, 'string')}
            />
            {role === ROLE_KEY.ADMIN && (
              <Button
                type='primary'
                icon={<PlusOutlined />}
                className='w-full md:w-[120px]'
                onClick={() => navigate(APP_ROUTER.CONTRACT.CREATE)}
              >
                {commonUI.register}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className='h-full min-h-[81vh] bg-white rounded-lg'>
        <div className='flex flex-col'>
          {isLoading ? null : (
            <div className={data?.data?.length ? '' : 'data-empty'}>
              <Table
                loading={isLoading}
                scroll={{ x: 1520 }}
                rowKey='id'
                columns={columns}
                dataSource={data?.data}
                pagination={false}
                bordered={false}
                locale={{ emptyText: <DataEmpty /> }}
              />
            </div>
          )}
          {data && data.pagination && data.pagination.total > 10 && (
            <Pagination
              className='pagination-table !pr-[5px]'
              total={data?.pagination.total}
              pageSize={params.pageSize}
              current={params.page}
              onChange={onChangePagination}
            />
          )}
        </div>
      </div>
      {isLoading && <Spin />}
    </div>
  );
};

export default ManagerContract;
