import { UserApi } from '@/apis';
import { Button, Field, Spin } from '@/components/common';
import { ErrorMessage, ErrorMessageKeys, MESSAGE } from '@/const/message';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { APP_ROUTER } from '@/routes/routes';
import { regex } from '@/utils';
import { commonUI } from '@/utils/text/UI/index';
import { useMutation } from '@tanstack/react-query';
import { Divider, Form, Input } from 'antd';
import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { messageUI } from '@/utils/text/message';

interface LoginForm {
  email: string;
  password: string;
  codeVerify: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required(messageUI.emailRequired)
    .matches(regex.validateEmail, messageUI.emailInvalid),
});

const ForgotPassword: React.FC = (): ReactElement => {
  const methods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = methods;
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: UserApi.forgotPassword,
    onSuccess: async () => {
      navigate(APP_ROUTER.AUTH.LOGIN);
      toastMessage(NotificationType.success, MESSAGE.FORGOT_PASSWORD_SUCCESS);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const onSubmit = async (values: LoginForm) => {
    mutation.mutate(values);
  };
  return (
    <div className='flex items-center bg-[#595959] h-screen'>
      <div className='p-8 m-0 m-auto w-[640px] bg-white rounded'>
        <div className='mb-[10px]'>
          <div
            className={'flex items-center text-xl justify-center font-semibold'}
          >
            {commonUI.forgotPassword}
          </div>
        </div>
        <Divider />
        <FormProvider {...methods}>
          <Form
            onFinish={handleSubmit(onSubmit)}
            className='flex flex-col items-center'
            labelAlign='left'
            layout='vertical'
          >
            <div className='w-full'>
              <Field name='email' label={messageUI.subForgotPassword}>
                <Input
                  placeholder={commonUI.email}
                  style={{ width: '100%' }}
                  maxLength={64}
                />
              </Field>
              <div className='text-[#8A8A8A]'>
                {messageUI.desForgotPassword}
              </div>
            </div>
            <Divider />
            <div className='w-full flex gap-[10px]'>
              <Button
                block={true}
                type='primary'
                htmlType='submit'
                onClick={() => navigate(APP_ROUTER.AUTH.LOGIN)}
              >
                {commonUI.backToLogin}
              </Button>
              <Button block={true} type='primary' htmlType='submit'>
                {commonUI.send}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
      {mutation.isPending && <Spin />}
    </div>
  );
};

export default ForgotPassword;
