import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

interface IPagination {
  current: number;
  total: number;
  pageSize: number;
}
export interface ICompanyDetail {
  id?: number;
  name: string;
  email: string;
  address: string;
  phone: string;
  fax: string;
  postCode: string;
  status: string;
  code: string;
  finishUrl: string;
  isVerifyAuthentic: boolean;
  users: {
    fullName: string;
    email: string;
    address: string;
    phone: string;
    dob: string | null;
    gender: number;
  }[];
}

interface IResponseCompany {
  data: ICompanyDetail[];
  pagination: IPagination;
}

export interface ValuesForm {
  id?: number;
  name: string;
  email: string;
  address: string;
  phone?: string;
  fax?: string;
  postCode?: string;
  status?: string;
  finishUrl?: string;
  users: {
    fullName: string;
    email: string;
    address?: string;
    phone?: string;
    dob?: string | null;
    gender: number;
  }[];
}
export interface WebhookForm {
  urlEndpoint: string;
  authorization: string;
  isFlag: boolean;
  headers: [];
}

class Company {
  getListCompany(params?: {
    page: number;
    pageSize: number;
  }): Promise<IResponseCompany> {
    return axiosClient
      .get(ENDPOINT.COMPANY.GET_LIST, { params })
      .then((res) => res.data.data);
  }
  getDetailCompany(id: number): Promise<ICompanyDetail> {
    return axiosClient
      .get(ENDPOINT.COMPANY.GET_DETAIL.replace(':id', String(id)))
      .then((res) => res.data.data);
  }
  createCompany(data: ValuesForm): Promise<ICompanyDetail> {
    return axiosClient
      .post(ENDPOINT.COMPANY.CREATE, data)
      .then((res) => res.data.data);
  }
  updateCompany(data: ValuesForm): Promise<ICompanyDetail> {
    return axiosClient
      .put(ENDPOINT.COMPANY.UPDATE.replace(':id', String(data.id)), data)
      .then((res) => res.data.data);
  }
  updateVerifyAuthentic(data: {
    id: number;
    is_verify_authentic: boolean;
  }): Promise<boolean> {
    return axiosClient
      .put(
        ENDPOINT.COMPANY.VERIFY_AUTHENTIC.replace(':id', String(data.id)),
        data,
      )
      .then((res) => res.data.data);
  }
  getWebhook(id: number): Promise<any> {
    return axiosClient
      .get(ENDPOINT.WEBHOOK.GET_DETAIL.replace(':id', String(id)))
      .then((res) => res.data.data);
  }
  createWebhook(data: WebhookForm): Promise<any> {
    return axiosClient
      .post(ENDPOINT.WEBHOOK.CREATE, data)
      .then((res) => res.data.data);
  }
  updateWebhook(data: WebhookForm): Promise<any> {
    return axiosClient
      .put(ENDPOINT.WEBHOOK.UPDATE, data)
      .then((res) => res.data.data);
  }
}

export const CompanyApi = new Company();
