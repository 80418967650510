import { CompanyApi } from '@/apis';
import {
  Button,
  DataEmpty,
  Field,
  FieldReadOnly,
  Modal,
  Spin,
} from '@/components/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Divider, Form, Row, Switch, Tag, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  companyStatusColors,
  companyStatusLabels,
  genderOptions,
  ROLE_KEY,
  STATUS_KEY,
} from '@/const/option';
import { getCookie, KeyCookie } from '@/utils/storage';
import { APP_ROUTER } from '@/routes/routes';
import { EditFilled, LeftCircleOutlined } from '@ant-design/icons';
import { commonUI } from '@/utils/text/UI';
import { ENDPOINT } from '@/apis/endpoint';
import { useEffect, useState } from 'react';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { messageUI } from '@/utils/text/message';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import { FormProvider, useForm } from 'react-hook-form';

interface DetailCompanyProps {
  companyId?: string;
}

const DetailCompany: React.FC<DetailCompanyProps> = () => {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const role = getCookie(KeyCookie.role)
    ? JSON.parse(getCookie(KeyCookie.role))
    : '';

  const {
    data: companyDetail,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [ENDPOINT.COMPANY.GET_DETAIL.replace(':id', id!)],
    queryFn: async () => {
      try {
        const response = await CompanyApi.getDetailCompany(Number(id));
        return response;
      } catch (error: any) {
        toastMessage(
          NotificationType.error,
          ErrorMessage[error.error as ErrorMessageKeys] ??
            getMessageSubmitForm(error as any),
        );
        throw error;
      }
    },
    enabled: !!id,
  });

  const methods = useForm<any>({
    mode: 'onChange',
  });

  const { setValue, watch } = methods;

  useEffect(() => {
    if (companyDetail) {
      setValue('checked', companyDetail.isVerifyAuthentic);
    }
  }, [companyDetail]);

  const mutationUpdate = useMutation({
    mutationFn: CompanyApi.updateVerifyAuthentic,
    onSuccess: async () => {
      setOpen(false);
      refetch();
      toastMessage(NotificationType.success, messageUI.updateSuccess);
    },
    onError: async (error: { error: string }) => {
      setOpen(false);
      setValue('checked', !watch('checked'));
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const onChange = (checked: boolean) => {
    setOpen(true);
    setValue('checked', checked);
  };

  const handleVerifyAuthentic = () => {
    mutationUpdate.mutate({
      id: Number(id),
      is_verify_authentic: watch('checked'),
    });
  };

  return (
    <div>
      {isLoading ? null : companyDetail ? (
        <div className='h-full flex flex-col items-center'>
          <FormProvider {...methods}>
            <Form
              labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
              labelAlign='left'
              className='w-full h-full flex flex-col'
            >
              <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
                <div className='w-full text-2xl font-semibold'>
                  {commonUI.detailCompany}
                </div>
              </div>
              <div className='h-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
                <div>
                  <div>
                    <div className='flex justify-between'>
                      <Typography.Title level={4}>
                        <div className='mb-4'>{commonUI.infoCompany}</div>
                      </Typography.Title>
                      <div>
                        <Button
                          type='primary'
                          block={true}
                          onClick={() =>
                            navigate(
                              APP_ROUTER.COMPANY.UPDATE.replace(
                                ':id',
                                String(id),
                              ),
                            )
                          }
                          className='w-full max-w-xs'
                          icon={<EditFilled />}
                        >
                          {commonUI.edit}
                        </Button>
                      </div>
                    </div>
                    <Row gutter={[64, 12]}>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.companyCode}>
                          {companyDetail?.code}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.companyPostCode}>
                          {companyDetail?.postCode}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.companyName}>
                          {companyDetail?.name}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.companyPhone}>
                          {companyDetail?.phone}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.email}>
                          {companyDetail?.email}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.companyFax}>
                          {companyDetail?.fax}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.address}>
                          {companyDetail?.address}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.status}>
                          <Tag
                            color={
                              companyStatusColors[
                                companyDetail?.status as keyof typeof companyStatusColors
                              ]
                            }
                            className={
                              companyDetail?.status === STATUS_KEY.ACTIVE
                                ? '!text-[#28C76F]'
                                : '!text-[#FD953E]'
                            }
                          >
                            {
                              companyStatusLabels[
                                companyDetail?.status as keyof typeof companyStatusLabels
                              ]
                            }
                          </Tag>
                        </FieldReadOnly>
                      </Col>
                    </Row>
                  </div>
                  <Divider />
                  <div className='mt-[20px]'>
                    <Typography.Title level={4} className='flex'>
                      <div className='mb-4'>{commonUI.infoRep}</div>
                    </Typography.Title>
                    <Row gutter={[64, 4]}>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.fullName}>
                          {companyDetail?.users[0]?.fullName}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.gender}>
                          {
                            genderOptions[
                              companyDetail?.users[0]
                                ?.gender as keyof typeof genderOptions
                            ]
                          }
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.email}>
                          {companyDetail?.users[0]?.email}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.address}>
                          {companyDetail?.users[0]?.address}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.birthday}>
                          {companyDetail?.users[0]?.dob}
                        </FieldReadOnly>
                      </Col>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.phone}>
                          {companyDetail?.users[0]?.phone}
                        </FieldReadOnly>
                      </Col>
                    </Row>
                  </div>
                  <Divider />
                  <div className='mt-[20px]'>
                    <Typography.Title level={4} className='flex'>
                      <div className='mb-4'>トークンの認証</div>
                    </Typography.Title>
                    <Row gutter={[64, 4]}>
                      <Col xs={24} sm={12}>
                        <Field name='checked' label='認証実行'>
                          <Switch
                            disabled={
                              role === ROLE_KEY.COMPANY ||
                              companyDetail.status === STATUS_KEY.IN_ACTIVE
                            }
                            defaultValue={companyDetail.isVerifyAuthentic}
                            onChange={onChange}
                          />
                        </Field>
                      </Col>
                    </Row>
                  </div>
                  <Divider />
                  <div className='mt-[20px]'>
                    <Typography.Title level={4} className='flex'>
                      <div className='mb-4'>{commonUI.infoUrl}</div>
                    </Typography.Title>
                    <Row gutter={[64, 4]}>
                      <Col xs={24} sm={12}>
                        <FieldReadOnly label={commonUI.finishUrl}>
                          {companyDetail?.finishUrl}
                        </FieldReadOnly>
                      </Col>
                    </Row>
                  </div>
                </div>
                {role === ROLE_KEY.ADMIN && (
                  <div className='flex gap-4 w-full mt-[30px] w-[80px]'>
                    <Button
                      onClick={() => navigate(APP_ROUTER.COMPANY.LIST)}
                      type='primary'
                      ghost
                      icon={<LeftCircleOutlined />}
                    >
                      {commonUI.back}
                    </Button>
                  </div>
                )}
              </div>
            </Form>
          </FormProvider>
        </div>
      ) : (
        <DataEmpty />
      )}
      {(isLoading || mutationUpdate.isPending) && <Spin />}
      <Modal
        open={open}
        onCancel={() => {
          setValue('checked', !watch('checked'));
          setOpen(false);
        }}
        title={commonUI.confirm}
        description={messageUI.update}
      >
        <div className='flex justify-center gap-[10px] p-[20px] bg-[#E6FAFD]'>
          <Button
            onClick={() => {
              setValue('checked', !watch('checked'));
              setOpen(false);
            }}
            className='w-full lg:w-[200px] bg-[#D6D9E0] hover:!bg-[#D9DDE6] text-gray-500 hover:!text-gray-500'
            type='primary'
          >
            {commonUI.no}
          </Button>
          <Button
            onClick={() => handleVerifyAuthentic()}
            className='w-full lg:w-[200px]'
            type='primary'
          >
            {commonUI.yes}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DetailCompany;
