import { Button, DataEmpty, FieldReadOnly, Spin } from '@/components/common';
import { Col, Divider, Form, Row, Typography } from 'antd';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTER } from '@/routes/routes';
import { useQuery } from '@tanstack/react-query';
import { ENDPOINT } from '@/apis/endpoint';
import { CompanyApi } from '@/apis';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import { getCookie, KeyCookie } from '@/utils/storage';
import { commonUI } from '@/utils/text/UI';
import { isFlagOptions } from '@/const/option';

const ManagerWebhook: React.FC = () => {
  const navigate = useNavigate();

  const user = getCookie(KeyCookie.currentUser);
  let parsedUser: any = {};
  if (user) {
    parsedUser = JSON.parse(user);
  }

  const { data, isLoading } = useQuery({
    queryKey: [ENDPOINT.WEBHOOK.GET_DETAIL, Number(parsedUser?.company.id)],
    queryFn: async () => {
      try {
        const response = await CompanyApi.getWebhook(
          Number(parsedUser?.company.id),
        );
        return response;
      } catch (error: any) {
        toastMessage(
          NotificationType.error,
          ErrorMessage[error.error as ErrorMessageKeys] ??
            getMessageSubmitForm(error as any),
        );
        throw error;
      }
    },
    enabled: !!parsedUser?.company.id,
  });

  return (
    <div>
      <div className='h-full flex flex-col items-center'>
        <Form
          labelCol={{ xs: 20, sm: 8, md: 6, lg: 12, xl: 6 }}
          className='w-full h-full flex flex-col'
          labelAlign='left'
        >
          <div className='bg-white p-6 rounded-lg py-[16px] px-[24px] mb-[10px]'>
            <div className='w-full text-2xl font-semibold'>
              {commonUI.settingWebhook}
            </div>
          </div>
          <div className='h-full min-h-[81vh] flex flex-col justify-between bg-white p-6 rounded-lg'>
            <div>
              <div className='flex justify-between'>
                <Typography.Title level={4}>
                  <div className='mb-4'>{commonUI.infoWebhook}</div>
                </Typography.Title>
                <div>
                  <Button
                    type='primary'
                    block={true}
                    onClick={() =>
                      data
                        ? navigate(APP_ROUTER.WEBHOOK.UPDATE)
                        : navigate(APP_ROUTER.WEBHOOK.CREATE)
                    }
                    className='w-full max-w-xs'
                    icon={data ? <EditFilled /> : <PlusOutlined />}
                  >
                    {data ? commonUI.edit : commonUI.btnCreateWebhook}
                  </Button>
                </div>
              </div>
              {isLoading ? null : data ? (
                <div>
                  <Row gutter={[16, 12]}>
                    <Col xs={24} sm={12}>
                      <FieldReadOnly label={commonUI.companyName}>
                        {parsedUser?.company.name}
                      </FieldReadOnly>
                    </Col>
                    <Col xs={24} sm={12}>
                      <FieldReadOnly label={commonUI.isFlag}>
                        {isFlagOptions[data?.isFlag ? 1 : 0]}
                      </FieldReadOnly>
                    </Col>
                    <Col xs={24} sm={12}>
                      <FieldReadOnly label={commonUI.urlEndPoint}>
                        {data?.urlEndpoint}
                      </FieldReadOnly>
                    </Col>
                    <Col xs={24} sm={12}>
                      <FieldReadOnly label={commonUI.authorization}>
                        {data?.authorization}
                      </FieldReadOnly>
                    </Col>
                  </Row>
                  {data?.headers?.length > 0 &&
                    data?.headers.map(
                      (
                        value: { headerName: string; headerValue: string },
                        index: number,
                      ) => (
                        <div key={index}>
                          <Divider />
                          <Row gutter={[16, 12]}>
                            <Col xs={24} sm={12}>
                              <FieldReadOnly label={commonUI.headerName}>
                                {value?.headerName}
                              </FieldReadOnly>
                            </Col>
                            <Col xs={24} sm={12}>
                              <FieldReadOnly label={commonUI.headerValue}>
                                {value?.headerValue}
                              </FieldReadOnly>
                            </Col>
                          </Row>
                        </div>
                      ),
                    )}
                </div>
              ) : (
                <DataEmpty />
              )}
            </div>
          </div>
        </Form>
      </div>
      {isLoading && <Spin />}
    </div>
  );
};

export default ManagerWebhook;
