import { ENDPOINT } from '@/apis/endpoint';
import { ScoreIndexApi } from '@/apis/scoreIndex';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Select, TableProps, Tag } from 'antd';
import { Form, InputNumber, Table } from 'antd';
import { convertObjectToArray } from '@/utils/helpers';
import { taskScoreColors, taskKeyLabels, TYPE_SCORE_KEY } from '@/const/option';
import {
  getMessageSubmitForm,
  NotificationType,
  toastMessage,
} from '@/hooks/toastMessage';
import { ErrorMessage, ErrorMessageKeys } from '@/const/message';
import { commonUI } from '@/utils/text/UI';
import { Button, DataEmpty, Spin } from '@/components/common';
import { messageUI } from '@/utils/text/message';

interface Item {
  key: string;
  x: string;
  age: string;
  y: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number';
  record: Item;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          className={'h-[54px]'}
          rules={[
            {
              required: true,
              message: `${title}が入力されていません。`,
            },
          ]}
        >
          <InputNumber min={1} max={9999} maxLength={9} className='w-full' />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ScoreIndex: React.FC = () => {
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState<Item[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [params, setParams] = useState({
    taskKey: Object.keys(taskKeyLabels)[0],
  });

  const {
    data: dataScoreIndex,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [ENDPOINT.SCORE_INDEX.GET_ALL, params],
    queryFn: () => ScoreIndexApi.getAll(params),
  });

  const mutationUpdate = useMutation({
    mutationFn: ScoreIndexApi.updateScoreIndex,
    onSuccess: async () => {
      refetch();
      toastMessage(NotificationType.success, messageUI.updateSuccess);
    },
    onError: async (error: { error: string }) => {
      toastMessage(
        NotificationType.error,
        ErrorMessage[error.error as ErrorMessageKeys] ??
          getMessageSubmitForm(error as any),
      );
    },
  });

  const isEditing = (record: Item) => record.key === editingKey;
  useEffect(() => {
    if (dataScoreIndex) {
      const convertData = dataScoreIndex.data?.map((el: any, i: number) => {
        return {
          key: i.toString(),
          task: el.task,
          age: el.age,
          x: el[TYPE_SCORE_KEY.HyojunHensa]?.value,
          y: el[TYPE_SCORE_KEY.HyojunTen]?.value,
        };
      });
      setOriginData(convertData);
    }
  }, [dataScoreIndex]);
  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ x: '', y: '', ...record });
    setEditingKey(record.key);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData: any = [...originData];
      const index = newData.findIndex((item: any) => key === item.key);

      const updateRecord = [
        {
          ...dataScoreIndex?.data[index][TYPE_SCORE_KEY.HyojunHensa],
          value: row.x,
        },
        {
          ...dataScoreIndex?.data[index][TYPE_SCORE_KEY.HyojunTen],
          value: row.y,
        },
      ];

      mutationUpdate.mutate(updateRecord);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const columns = [
    {
      title: commonUI.task,
      dataIndex: 'task',
      width: '60px',
      render: (task: string) => {
        return (
          <Tag color={taskScoreColors[task as keyof typeof taskScoreColors]}>
            {taskKeyLabels[task as keyof typeof taskKeyLabels]}
          </Tag>
        );
      },
    },
    {
      title: commonUI.age,
      dataIndex: 'age',
      width: '80px',
    },
    {
      title: commonUI.type2,
      dataIndex: 'y',
      width: '80px',
      editable: true,
    },
    {
      title: commonUI.type1,
      dataIndex: 'x',
      width: '80px',
      editable: true,
    },
    {
      width: '50px',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <div className={'h-[54px]'}>
            <Button
              type='primary'
              className='tracking-[-1.5px]'
              onClick={() => save(record.key)}
              style={{ marginInlineEnd: 15 }}
            >
              {commonUI.update}
            </Button>
            <Button type='default' onClick={() => setEditingKey('')}>
              {commonUI.back}
            </Button>
          </div>
        ) : (
          <Button
            type='primary'
            className='tracking-[-1.5px]'
            disabled={editingKey !== ''}
            onClick={() => {
              edit(record);
            }}
          >
            {commonUI.edit}
          </Button>
        );
      },
    },
  ];

  const mergedColumns: TableProps<Item>['columns'] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className='h-full flex flex-col'>
      <div className='bg-white px-[24px] py-[16px] rounded-lg mb-[10px]'>
        <div className='flex flex-col justify-between gap-4 md:flex-row'>
          <div className='w-full text-2xl font-semibold truncate'>
            {commonUI.scoreIndex}
          </div>
          <div className='flex flex-col md:flex-row gap-[10px] justify-end w-full'>
            <Select
              className='w-full md:w-[200px]'
              placeholder={commonUI.taskKey}
              defaultValue={Object.keys(taskKeyLabels)[0]}
              onChange={(e) => {
                setParams((prev) => ({
                  ...prev,
                  taskKey: e,
                }));
                setEditingKey('');
              }}
              options={convertObjectToArray(taskKeyLabels, 'string')}
            />
          </div>
        </div>
      </div>
      <div className='h-full bg-white rounded-lg'>
        {isLoading ? null : dataScoreIndex?.data && originData?.length > 0 ? (
          <Form form={form} component={false}>
            <Table
              scroll={{ y: 'calc(100vh - 235px)' }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={originData}
              columns={mergedColumns}
              rowClassName='editable-row'
              pagination={false}
            />
          </Form>
        ) : (
          <DataEmpty />
        )}
      </div>
      {isLoading && <Spin />}
    </div>
  );
};

export default ScoreIndex;
