import { FC, ReactElement } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LayoutComponent from '@/layout';
import Login from '@/pages/Login';
import ChangePassword from '@/pages/Login/ChangePassword';
import ForgotPassword from '@/pages/Login/ForgotPassword';
import ManagerCompany from '@/pages/ManagerCompany';
import DetailCompany from '@/pages/ManagerCompany/Detail';
import FormCompany from '@/pages/ManagerCompany/Form';
import UserInfo from '@/pages/UserInfo';
import ChangePasswordInfo from '@/pages/UserInfo/ChangePassword';
import { getCookie, KeyCookie } from '@/utils/storage';
import { Outlet } from 'react-router-dom';
import Unauthorized from '@/pages/Unauthorized';
import ManagerContract from '@/pages/ManagerContract';
import FormContract from '@/pages/ManagerContract/Form';
import DetailContract from '@/pages/ManagerContract/Detail';
import NotFound from '@/pages/NotFound';
import ProtectedRoutes from './routes/ProtectedRoutes';
import { useStore } from './store';
import RoleBasedRoute from './routes/PrivateRoute';
import ScoreIndex from './pages/ScoreIndex';
import ManagerWebhook from './pages/ManagerWebhook';
import FormWebhook from './pages/ManagerWebhook/Form';

const App: FC = (): ReactElement => {
  const [state] = useStore();
  const { isAuthenticated } = state;
  const role = getCookie(KeyCookie.role)
    ? JSON.parse(getCookie(KeyCookie.role))
    : 'GUEST';

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Outlet />}>
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/change-password/:code' element={<ChangePassword />} />
            <Route path='/not-found' element={<NotFound />} />
          </Route>
          <Route
            element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}
          >
            <Route element={<LayoutComponent />}>
              <Route index element={<ManagerContract />} />
              <Route
                element={
                  <RoleBasedRoute
                    isAuthenticated={isAuthenticated}
                    allowedRoles={['ADMIN']}
                    userRole={role}
                  />
                }
              >
                <Route path='/contract/:id' element={<FormContract />} />
                <Route path='/company' element={<ManagerCompany />} />
                <Route path='/company/create' element={<FormCompany />} />
                <Route path='/contract/create' element={<FormContract />} />
                <Route path='/score-index' element={<ScoreIndex />} />
              </Route>
              <Route
                element={
                  <RoleBasedRoute
                    isAuthenticated={isAuthenticated}
                    allowedRoles={['COMPANY']}
                    userRole={role}
                  />
                }
              >
                <Route path='/user-info' element={<UserInfo />} />
                <Route path='/webhook' element={<ManagerWebhook />} />
                <Route path='/webhook/create' element={<FormWebhook />} />
                <Route path='/webhook/update' element={<FormWebhook />} />
              </Route>
              <Route path='/contract' element={<ManagerContract />} />
              <Route path='/contract/detail/:id' element={<DetailContract />} />
              <Route path='/company/detail/:id' element={<DetailCompany />} />
              <Route path='/company/:id' element={<FormCompany />} />
              <Route path='/change-password' element={<ChangePasswordInfo />} />
              <Route path='/403' element={<Unauthorized />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
